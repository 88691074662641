import React from "react"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"

import TermsOfLicense from "../../components/sections/legal/terms-of-license"
import Footer from "../../components/sections/footer"

const TermsOfLicensePage = () => (
  <Layout>
    <SEO title="Terms of License" />
    <Navigation />
    <TermsOfLicense />
    <Footer />
  </Layout>
)

export default TermsOfLicensePage
