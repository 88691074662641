import React from "react"
import { Link } from "gatsby"

import { BasicPageContainer } from "../../global"

const TermsOfLicense = () => (
  <BasicPageContainer>
    <h1>Terms of License</h1>
    <h2>Overview</h2>
    <p>
      Welcome to the Dismissly website and application (the “Site”). The Site
      contains a simple solution to allow schools to accomplish dismissal after
      school with a minimum of hassle (the “Services”). The following, along
      with the <Link to="/legal/terms-of-use">Terms of Use</Link> and{" "}
      <Link to="/legal/privacy-policy">Privacy Policy</Link> are terms of a
      legal agreement (collectively, the “Terms”) between you and the owner of
      the Site, Droplet, LLC. Droplet values its relationship with its
      customers, so these Terms of License are provided to ensure clarity in the
      relationship and ease of making transactions. By purchasing a license on
      the Site, you acknowledge that you have read, understood, and agree to be
      bound by these Terms of License and to comply with all applicable laws and
      regulations. If you do not agree to these Terms, please do not use or make
      a purchase on the Site. These Terms of License may change from time to
      time without notice to you, so be sure to check them each time you make a
      purchase on the Site. The most recent date on which these Terms of License
      were updated will always appear at the bottom of this page.
    </p>
    <p>
      All transactions will be governed under the general commercial laws of the
      State of Iowa as supplemented or modified by this Agreement, without
      regard to conflict of law principles. Droplet will accept your order only
      if (1) you accept the terms of these Terms of License (“TOL”), (2) you
      make payment in a timely fashion, either through the Site or by check, and
      (3) you upload only directory information (in compliance with FERPA) to
      the Site. Droplet reserves the right to cancel your order in its sole
      discretion, for any reason.
    </p>
    <p>
      Confirmation of receipt of your order does not constitute acceptance of
      your order; your order is accepted only when you receive confirmation that
      your school’s accounts have been authorized for setup. The TOL, including
      associated warranty statements or attachments, are the complete agreement
      between you and Droplet regarding your license and replaces any prior oral
      or written communications between you and Droplet. Any additional or
      different terms in any order or written communications from you are void.
      If there is a commercial agreement in effect between you and Droplet, then
      the terms of the commercial agreement govern this transaction.
    </p>
    <h2>Prices</h2>
    <p>
      The prices displayed on the Site are quoted in U.S. dollars and are valid
      and effective only within the United States. If you are located outside
      the United States, you may not make a license purchase on the Site.
    </p>
    <p>
      If a license is quoted at an incorrect price due to typographical error or
      error in pricing information: 1) Droplet has the right to refuse or cancel
      any orders placed for the license quoted at the incorrect price, even if
      Droplet has confirmed the receipt of your order and charged your credit or
      debit card; and 2) if Droplet has charged your credit or debit card but
      subsequently canceled your order, Droplet will promptly issue a credit to
      your credit or debit card account for the amount charged. You are
      responsible for taxes, if any, for each license purchase you make on the
      Site. No other discounts, quantity entitlements, or promotions apply
      unless expressly specified for this transaction.
    </p>
    <h2>Payments</h2>
    <p>
      Droplet uses a third-party payment gateway, Stripe. You can find out more
      about Stripe at <a href="https://www.stripe.com">www.stripe.com</a>. When
      you order something from the Site, Stripe will ask you to provide personal
      information including but not limited to your name, physical address,
      telephone number, and credit or debit card information. This information
      will be used to process your order and payment, and for related purposes.
      Droplet does not have access to this information. For additional
      information, please read Stripe’s Privacy Policy at{" "}
      <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
    </p>
    <p>
      Droplet accepts credit and debit card payments only through the Site. If
      you wish to order using a check, please contact Droplet directly at
      support@dismissly.com. You will receive information regarding how to
      create your accounts for the Dismissly app within three (3) business days
      after Droplet has received your payment, regardless of method of payment.
    </p>
    <h2>License Terms</h2>
    <p>
      When you make a purchase on the Site, you are purchasing a license to
      create one or more accounts on the Site in order to access the Services.
      Droplet and its licensors retain copyright, trademark, patent, trade
      secret, and all other intellectual property rights in and to all materials
      on the Site. When you purchase a license, Droplet grants you a
      non-exclusive, non-transferable, limited and revocable permission to
      access and display the Web pages within the Site for the purpose of
      accessing and using the Services. You are responsible for providing your
      own hardware, software, and internet connection in order to access and use
      the Site and the Services. This license is conditioned upon your leaving
      intact all copyright, trademark, and other proprietary notices.
    </p>
    <p>
      By uploading Your Data (as defined in the{" "}
      <Link to="/legal/terms-of-use">Terms of Use</Link>) to the Site, you are
      granting Droplet a revocable, royalty-free, sublicenseable (in accordance
      with the Terms), non-exclusive license to use, modify, delete from, add
      to, create derivative works of, perform, display, reproduce and distribute
      Your Data for the sole purpose of providing you the Services.
    </p>
    <p>
      Please be aware that you are responsible for ensuring that your order is
      in compliance with all local laws.
    </p>
    <h2>Student Data</h2>
    <p>
      Droplet does not wish to have access to any student data that is
      designated as an Education Record under the Family Educational Rights and
      Privacy Act and its implementing regulations (“FERPA”). The Site and
      Services are deliberately designed to minimize the amount of personally
      identifiable information collected. You should provide <em>only</em>{" "}
      information that has been properly designated Directory Information under
      FERPA to Droplet.{" "}
      <strong>
        It is a violation of the Terms to provide any information about a
        student to Droplet, either through the Site or the Services or
        otherwise, that is not properly designated Directory Information under
        FERPA.
      </strong>{" "}
      Such violations are subject to the Indemnification terms of the{" "}
      <Link to="/legal/terms-of-use">Terms of Use</Link>.
    </p>
    <h2>Cancellation; No Refunds</h2>
    <p>
      Your license to use the Site and the Services will last for the period of
      time you have designated through the purchase portal (the “Term”). Unless
      you indicate otherwise at least five (5) business days in advance, your
      license will automatically renew at the current license price for one (1)
      additional Term at the end of your current license Term. Droplet may
      update pricing at any time in its sole discretion; you will receive 30
      days’ notice of any changes to pricing at the email address we have on
      file for the administrator of your entity or organization’s payment
      account. You are free to cancel automatic renewal of your license with no
      less than five (5) business days’ notice before the end of the
      then-current license Term, and the license will terminate at the end of
      the then-current Term and will not renew. No refunds are available.
    </p>
    <h2>Disclaimer of Warranty</h2>
    <p>
      USE OF THIS SITE AND THE SERVICES IS AT YOUR SOLE RISK. ALL MATERIALS,
      INFORMATION, SERVICES AND PRODUCTS ARE PROVIDED “AS IS,” WITH NO
      WARRANTIES OR GUARANTEES WHATSOEVER. DROPLET EXPRESSLY DISCLAIMS TO THE
      FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, STATUTORY, AND OTHER
      WARRANTIES, GUARANTEES, OR REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION,
      THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
      NON-INFRINGEMENT OF PROPRIETARY AND INTELLECTUAL PROPERTY RIGHTS. WITHOUT
      LIMITATION, DROPLET MAKES NO WARRANTY OR GUARANTEE THAT THIS SITE OR THE
      SERVICES WILL BE UNINTERRUPTED, CORRECT, COMPLETE, TIMELY, SECURE, OR
      ERROR-FREE OR THAT DEFECTS IN THIS WEB SITE WILL BE CORRECTED.
    </p>
    <p>
      YOU UNDERSTAND AND AGREE THAT IF YOU OBTAIN MATERIALS, INFORMATION,
      PRODUCTS, OR SERVICES, YOU DO SO AT YOUR OWN DISCRETION AND RISK AND THAT
      YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES THAT MAY RESULT, INCLUDING
      LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM.
    </p>
    <p>
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF WARRANTIES, SO THE ABOVE
      EXCLUSIONS MAY NOT APPLY TO YOU.
    </p>
    <h2>Limitation of Liability</h2>
    <p>
      IN NO EVENT WILL DROPLET BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT,
      INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY TYPE
      WHATSOEVER RELATED TO OR ARISING FROM THIS SITE OR THE SERVICES OR ANY USE
      OF THIS SITE OR THE SERVICES, OR OF ANY SITE OR RESOURCE LINKED TO,
      REFERENCED, OR ACCESSED THROUGH THE SITE, OR FOR THE USE OR DOWNLOADING
      OF, OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, OR SERVICES,
      INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION,
      LOST SAVINGS OR LOSS OF PROGRAMS OR OTHER DATA, EVEN IF DROPLET IS
      EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS EXCLUSION AND
      WAIVER OF LIABILITY APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED ON
      CONTRACT, WARRANTY, TORT, OR ANY OTHER LEGAL THEORIES.
    </p>
    <h2>Disputes</h2>
    <p>
      You agree that any claim or controversy arising out of or relating to the
      Site, the Services, or to any acts or omissions for which you may contend
      Droplet is liable, including but not limited to any claim or controversy
      as to arbitrability ("Dispute"), shall be finally and exclusively settled
      by arbitration. The arbitration shall be venued in Des Moines, Iowa, and
      shall apply the laws of the State of Iowa, excluding its conflicts of laws
      provisions. Arbitration will be conducted under the rules of the American
      Arbitration Association ("AAA"). The parties will jointly agree upon a
      neutral arbitrator. Any judgment upon the award rendered by the
      arbitrators may be entered in any court of competent jurisdiction. The
      agreement to arbitrate shall not be construed as an agreement to the
      joinder or consolidation of arbitration under this agreement with
      arbitration of disputes or claims of any non-party, regardless of the
      nature of the issues or disputes involved. To the fullest extent permitted
      by applicable law, no arbitration under these TOL shall be joined to an
      arbitration involving any other party subject to these TOL, whether
      through class arbitration proceedings or otherwise.
    </p>
    <p>
      THIS AGREEMENT PROVIDES THAT ALL DISPUTES BETWEEN YOU AND DROPLET WILL BE
      RESOLVED BY BINDING ARBITRATION. YOU THUS GIVE UP YOUR RIGHT TO GO TO
      COURT TO ASSERT OR DEFEND YOUR RIGHTS. YOU ALSO GIVE UP YOUR RIGHT TO
      PARTICIPATE IN OR BRING CLASS ACTIONS. YOUR RIGHTS WILL BE DETERMINED BY
      NEUTRAL ARBITRATORS AND NOT A JUDGE OR JURY. You are entitled to a fair
      hearing, and the arbitration procedures are simpler and more limited than
      rules applicable in court. Arbitrator decisions are enforceable as any
      court order and are subject to very limited review by a court. By using
      the Site and the Services and by purchasing a license under these TOL, you
      consent to these restrictions.
    </p>
    <p>
      Should a Dispute arise and should the arbitration provisions herein become
      inapplicable or unenforceable, or in any instance of any lawsuit between
      you and Droplet, you agree that jurisdiction over and venue of any suit
      shall be exclusively in the state and federal courts sitting in Des
      Moines, Iowa. If either party employs attorneys to enforce any right in
      connection with any Dispute or lawsuit, the prevailing party shall be
      entitled to recover reasonable attorneys' fees.
    </p>
    <p>
      If any part of this agreement is invalid or unenforceable under applicable
      law, the invalid or unenforceable provision will be deemed superseded by a
      valid, enforceable provision that most closely matches the intent of the
      original provision and the remainder of the agreement shall govern such
      use.
    </p>
    <p>
      <strong>Last revised: August 3, 2020.</strong>
    </p>
  </BasicPageContainer>
)

export default TermsOfLicense
